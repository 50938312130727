import { useNavigate } from "react-router-dom";
import "./drawing.styles.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Drawing = ({ id, onLoad }) => {
  const path = `/drawings/${id}.png`;
  const navigate = useNavigate();

  return (
    <div className="Drawing">
      <LazyLoadImage
        src={path}
        alt="tattoo flash drawing"
        onClick={() => {
          navigate(`/drawing/${id}`);
        }}
        onLoad={onLoad(id)}
      />
    </div>
  );
};

export default Drawing;
