import BookingButton from "../booking-button/booking-button.component";
import Logo from "../logo/logo.component";
import Nav from "../nav/nav.component";
import "./header.styles.scss";

const Header = ({ canvasRef }) => {
  return (
    <header className="Header">
      <div className="header">
        <div className="logo">
          <Logo />
        </div>
        <div className="nav">
          <Nav canvasRef={canvasRef} />
        </div>
        <div className="booking">
          <BookingButton />
        </div>
      </div>
    </header>
  );
};

export default Header;
