import "./booking-button.styles.scss";

const BookingButton = () => {
  return (
    <div className="Booking">
      <a href="https://calendly.com/snuggelug/booking?month=2023-07" target="_blank" rel="noopener noreferrer">
        <img src="/buttons/Booking.gif" alt="Booking" />
      </a>
    </div>
  );
};

export default BookingButton;
