import "./loading.styles.scss";

const Loading = () => {
  return (
    <div className="Loading">
        <p>Loading drawings...</p>
    </div>
  );
};

export default Loading;
