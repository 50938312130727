import { Route, Routes } from "react-router-dom";
import Landing from "./pages/landing/landing.component";
import DrawingPage from "./pages/drawing-page/drawing-page.component";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Landing />} />
        <Route path="*" element={<Landing />} />
        <Route path="drawing/:id" element={<DrawingPage />} />
      </Route>
    </Routes>
  );
}

export default App;
