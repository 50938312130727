import "./logo.styles.scss";

const Logo = () => {
  return (
    <div className="Logo">
      <img src="/logo.png" alt="logo" />
    </div>
  );
};

export default Logo;
