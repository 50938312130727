import Button from "../button/button.component";
import "./nav.styles.scss";

const Nav = ({ canvasRef }) => {
  const portfolio = () => {
    canvasRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const instagram = () => {
    window.open("https://www.instagram.com/snuggelug/", "_blank").focus();
  };

  const contact = () => {
    window.open('mailto:snuggelug@gmail.com')
  };

  return (
    <div className="Nav">
      <Button name="Portfolio" onClick={portfolio} />
      <Button name="Instagram" onClick={instagram} />
      <Button name="Contact" onClick={contact} />
    </div>
  );
};

export default Nav;
