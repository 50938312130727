import { useEffect, useMemo, useState } from "react";
import Drawing from "../drawing/drawing.component";
import "./canvas.styles.scss";
import Loading from "../loading/loading.component";

const importAll = (r) => {
  return r.keys().map(r);
};

const getId = (s) => {
  return s.substring(s.lastIndexOf("/") + 1, s.indexOf("."));
}

const collator = new Intl.Collator([], { numeric: true });

const Canvas = ({ canvasRef }) => {
  const [images, setImages] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [imagesRendering, setImagesRendering] = useState(true);

  useEffect(() => {
    const newImages = importAll(
      require.context("../../../public/drawings/", false, /\.(png|jpe?g|svg)$/)
    );
    setImages(newImages);
    setImagesLoading(false);
  }, [])

  images.sort((a, b) => collator.compare(a, b));
  images.reverse();

  const initialWidth = 95;
  const [width, setWidth] = useState(initialWidth);
  const handleScroll = () => {
    const w = Math.max(initialWidth, (window.scrollY * 100) / 800);
    setWidth(Math.min(w, 95));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const loadedImages = useMemo(() => [], []);;
  const onImageLoad = (id) => {
    loadedImages.push(id)
  }

  useEffect(() => {
    if(loadedImages.length === images.length * 2){
      setImagesRendering(false)
    }
  }, [loadedImages, images.length])

  return (
    <div className="Canvas" ref={canvasRef}>
      {imagesLoading && imagesRendering && <Loading/>}
      {!imagesLoading && !imagesRendering && <div className="canvas" style={{ width: `${width}%` }}>
        {images.map((image) => (
          <Drawing key={getId(image)} id={getId(image)} onLoad={onImageLoad}/>
        ))}
      </div>}
    </div>
  );
};

export default Canvas;
