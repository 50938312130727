import ScrollToTop from "../../components/scroll-to-top/scroll-to-top.component";
import Canvas from "../../components/canvas/canvas.component";
import Header from "../../components/header/header.component";
import "./landing.styles.scss";
import { useEffect, useRef } from "react";

const Landing = () => {
  const canvasRef = useRef(null);

  const scrollPosition = localStorage.getItem("scrollPosition");

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, scrollPosition), 100);
  }, [scrollPosition]);

  const handleScroll = () => {
    const position = window.scrollY;
    localStorage.setItem("scrollPosition", position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="Landing" onScroll={handleScroll}>
      <Header canvasRef={canvasRef} />
      <Canvas canvasRef={canvasRef} />
      <ScrollToTop />
    </div>
  );
};

export default Landing;
