import "./button.styles.scss";

const Button = ({ name, onClick }) => {
  const path = `/buttons/${name}.png`;
  return (
    <div className="Button" onClick={onClick}>
      <img src={path} alt={name} />
    </div>
  );
};

export default Button;
