import BookingButton from "../../components/booking-button/booking-button.component";
import "./drawing-page.styles.scss";
import { useParams } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const DrawingPage = () => {
  const { id } = useParams();
  const path = `/drawings/${id}.png`;

  let navigate = useNavigate();

  useEffect(() => {
    const element = document.getElementById('DrawingPage');
    element.scrollIntoView();
 }, []);

  return (
    <div className="DrawingPage" id="DrawingPage">
      <div className="drawingPage">
        <div className="drawing">
          <div className="backButton" onClick={() => navigate(`/`)}>
            <IoChevronBackCircleOutline />
            <p>Back</p>
          </div>
          <img src={path} alt="tattoo flash drawing" />
        </div>

        <div className="text">
          <p>
            After setting your appointment, screenshot your desired tattoo and
            send it to me on Instagram.
          </p>
          <p>
            <a
              href="https://www.instagram.com/snuggelug/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @snuggelug
            </a>
          </p>
          <div className="bookingButton">
            <BookingButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawingPage;
